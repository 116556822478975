<template lang="pug">
div.cash-payment
  .costs-list-subtitle.m-b-30
      strong {{ $t("financeForms.downPayment") }}:
  .d-flex.justify-content-center
    div.contract-choicer-container(v-for="(downPayment, index) in downPayments" :key="index")
      input.radio-block-input(
        type="radio"
        v-validate="'required'"
        :checked="downPayment.monthly_cost === currentMonthlyPrice"
        :id="`downPayment${index}`"
        :value="downPayment.value"
        @change.stop="setDownPaymentId(downPayment)"
      )
      label.radio-block(:for="`downPayment${index}`")
        .radio-block-title
          strong {{formatPrice(downPayment.value)}} {{ currencySymbol }}
        div(v-if="showPrices")
          .radio-block-info(v-if="downPayment.value === vehicle.nonRefundableCashPayment") {{ $t("steps.costs.standard") }}
          .radio-block-info(v-else)
            span {{ formatPriceNegative(downPayment.monthly_cost - selectedDistance.monthly_cost) }}
            | {{ currencySymbol }}/{{ $t('mon') }}

    small.text-danger {{ errors.first('getDefaults[getPriceForm][distance]:required') }}
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import { formatPrice, formatPriceNegative } from '@/helpers/helpers.js';
import CostsMixin from '@/mixins/CostsMixin'

export default {
  mixins: [
    CostsMixin
  ],
  props: {
    downPayments: Array,
    // selectedDistance: Object,
    showPrices: Boolean,
  },
  computed: {
    ...mapGetters('product', [ 'getDefaults', 'getPriceForm']),
    ...mapState('product', [ 'vehicle', 'currentMonthlyPrice']),
    ...mapState('reseller', {
      customStyle: state => state.customStyle.firstStep || {},
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
  },
  methods: {
    formatPrice,
    formatPriceNegative,
    ...mapActions('product', ['getFixedCostByMonthAndDistance', 'updateDefaults', ]),
   async setDownPaymentId(downPayment) {
     await this.getFixedCostByMonthAndDistance([this.getDefaults[this.getPriceForm]['months'], this.getDefaults[this.getPriceForm]['distance']])
     await this.$store.commit('order/setVehicleCostDownPaymentId',  downPayment.id)
     await this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': downPayment.monthly_cost})
     await this.$store.dispatch('product/setCurrentMonthlyPrice', downPayment.monthly_cost)

     this.updateDefaults({
      form: this.getPriceForm,
      downPayment: downPayment.value,
      price: downPayment.monthly_cost
     })
    },
  },
}
</script>